import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_foto'
import TytulPunkty from '../comp/tytul_punkty'
import Wyniki from '../comp/wyniki'



export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/magic/6/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }
                slider_1: file(relativePath: { eq: "projekty/magic/6/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    }
    
                    slider_2: file(relativePath: { eq: "projekty/magic/6/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    }
    
    
                    slider_3: file(relativePath: { eq: "projekty/magic/6/slider/3@2x.jpg" }) {
                        childImageSharp {
                            fixed(width: 360, height: 360) {
                                ...GatsbyImageSharpFixed
                            }
                    }
                    }
    
                    slider_4: file(relativePath: { eq: "projekty/magic/6/slider/4@2x.jpg" }) {
                        childImageSharp {
                            fixed(width: 360, height: 360) {
                                ...GatsbyImageSharpFixed
                            }
                    }
                    }

                    wyniki: file(relativePath: { eq: "projekty/magic/6/wyniki@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                    }
                    }
            }
            `)


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]



    return (
        <>

            <Rozdzial tytul="MEDIA DIRECTION" invert={true} tekst="Obsługa zawodnika w mediach ma znacznie szerszy wymiar niż wyłącznie produkcja treści. Współpracując z Maćkiem, zarządzamy całym wizerunkiem zawodnika, łącznie z kontaktem z mediami, aranżowaniem wywiadów, autoryzacją treści i lokowaniem zawodnika w odpowiednich kontekstach. Dodatkowo, obsługujemy wszystkie platformy społecznościowe, zarządzamy stroną internetową, wspieramy kontakty ze sponsorami i realizujemy działania na rzecz ich ekosystemów." nr="06." img={data.okladka.childImageSharp.fluid}>

                <TytulPunkty tekst="Przez lata współpracy Stworzyliśmy nową  jakość  w polskim żużlu. Media społecznościowe Maćka to największy ekosystem w branży." tekst1="W 2021 roku Maciej janowski został uznany najlepszym żużlowcem w mediach społecznościowych w rankingu Wirtualnej Polski. Wyprzedził tym samym dwukrotnego Mistrza Świata, Bartosza Zmarzlika." tekst2="Od 2018 roku Maciej Janowski wybierany jest najpopularniejszym sportowcem na Dolnym Śląsku w rankingu Gazety Wrocławskiej. "/>

                <Slider foto={foto} tytul="Działania w mediachspołecznościowych"/>

                <Punkt tytul="Liczby w social mediach" tekst="Zarówno profil Maćka Janowskiego na platformie Facebook i na Instagramie, to najpopularniejsze kanały żużlowe w Polsce i jedne z największych tego typu profili na świecie. Poznajcie liczby, które wspólnie wypracowaliśmy." />

                <Wyniki
                postac={data.wyniki.childImageSharp.fluid}
                wynik1="60 tys" tekst1="brak" wynik2="10 tys" tekst2="brak" wynik3="7 tys" tekst3="brak" />

                

            </Rozdzial>

        </>
    )
}