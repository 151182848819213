import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Komp_zdjecie from '../comp/kol_tekst'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Widoki from '../comp/widoki_projektu'
import Wyniki from '../comp/wyniki'



export default function Ilustracja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/betard/4/slider/1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/betard/4/slider/2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/betard/4/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/4/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/magic/4/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja1: file(relativePath: { eq: "projekty/betard/4/ilustracja1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja2: file(relativePath: { eq: "projekty/betard/4/ilustracja2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                tablet: file(relativePath: { eq: "projekty/magic/4/tablet@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komputer: file(relativePath: { eq: "projekty/magic/4/komputer@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                mobile: file(relativePath: { eq: "projekty/magic/4/mobile@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                
                komp1: file(relativePath: { eq: "projekty/magic/4/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                postac: file(relativePath: { eq: "projekty/magic/4/postac@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    
    
    return(
        <>

        <Rozdzial tytul="WEB DESIGN" invert={true} tekst="Prezentowana strona zrealizowana została na początku 2021 roku. Niezbędnymi funkcjonalnościami były sklep, umożliwiający sprzedaż gadżetów wśród kibiców, informacje o Teamie, nadchodzących imprezach sportowych oraz klasyfikacjach zawodów, w których startuje Maciej. Istotna była optymalizacja sposobu zamieszczania treści i jej aktualizacji, tak, by przy ograniczonych zasobach, nie dezaktualizowała się, jednocześnie budując spójny i atrakcyjny wizerunek zawodnika. " nr="04." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Nasz sprawdzony proces tworzenia rozwiązań cyfrowych sprawił, że strona mogła powstać  w szybkim tempie, jednocześnie zachowując swoją  unikalność i atrakcyjność."/>

        
    
        

        <Widoki 
        img1={data.komputer.childImageSharp.fluid}
        img2={data.tablet.childImageSharp.fluid}
        img3={data.mobile.childImageSharp.fluid}
        tytul="Widoki strony"
        tekst="Zobacz jak wygląda obecna wersja strony www.teamjanowski.com na wszystkich najważniejszych urządzeniach. Po więcej odwiedź stronę Teamu."
        />

        <Komp_zdjecie  img={data.komp1.childImageSharp.fluid} tytul="Widoki strony" tekst="Prezentujemy wybrane przykłady strony teamjanowski.com. Łącznie w projekcie stworzyliśmy przeszło 50 odrębnych widoków, zawierających zróżnicowane treści, które tworzone były przez nas kompleksowo, od podstaw - w tym fotografie, animacje i materiały video. Chcesz zobaczyć więcej? Odwiedź stronę teamjanowski.com." />

        
        <TytulPunkty 
        tekst="Jak przystało na jednego z najbardziej medialnych zawodników, strona Maćka jest jedną z najczęściej odwiedzanych witryn żużlowców w Polsce i na świecie."/>

        <Wyniki
        postac={data.postac.childImageSharp.fluid}
        wynik1="60 tys" tekst1="wejść na stronę Teamjanowski.com rocznie" wynik2="10 tys" tekst2="Unikalnych UŻYTKOWNIKÓWODWIEDZAJĄCYCH WITRYNĘ rocznie" wynik3="7 tys" tekst3="TRANSAKCJI SKLEPOWYCH NA STRonie teamU W SKALI ROKU" />

        
  
        




       
        
        
        </Rozdzial>
        
        </>
    )
}