import React, {useState} from 'react'
import style from './widoki_projektu.module.css'
import Img from "gatsby-image"

export default function Widoki(props, img1, img2, img3, tytul, tekst) {

    const [widok, ustawWidok] = useState('komputer');

  

    return(
        <>
        <div className={style.wrapper}>

            <div className={style.wybor}>

                <span className="przycisk">
                    {props.tytul}
                </span>
                <p>
                    {props.tekst}
                </p>


                <div className={style.wybor_przycisk} onClick={() => ustawWidok('komputer')}
                style={widok === 'komputer' ? {opacity: '1'} : {opacity: '0.5'}}>
                    <span className="przycisk">
                        KOMPUTER
                    </span>
                </div>
                <div className={style.wybor_przycisk} onClick={() => ustawWidok('tablet')}
                style={widok === 'tablet' ? {opacity: '1'} : {opacity: '0.5'}}
                >
                    <span className="przycisk" >
                        TABLET
                    </span>
                </div>
                <div className={style.wybor_przycisk} onClick={() => ustawWidok('mobile')}
                style={widok === 'mobile' ? {opacity: '1'} : {opacity: '0.5'}}>
                    <span className="przycisk">
                        TELEFON
                    </span>
                </div>
    
                
            </div>


            <div className={style.zdjecie}>
                <Img fluid={props.img1} style={widok === 'komputer' ? {opacity: '1'} : {opacity: '0'}}/>
                <Img fluid={props.img2} style={widok === 'tablet' ? {opacity: '1'} : {opacity: '0'}}/>
                <Img fluid={props.img3} style={widok === 'mobile' ? {opacity: '1'} : {opacity: '0'}}/>
            </div>
        
        </div>
        </>
    )
}