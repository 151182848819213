import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'



export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/magic/7/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }

                slider_1: file(relativePath: { eq: "projekty/magic/7/slider1/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/magic/7/slider1/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/magic/7/slider1/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                prezentacje: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                    prezentacje2: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol2@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }

                    prezentacja: file(relativePath: { eq: "projekty/betard/7/prezentacja@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }


                    mockup: file(relativePath: { eq: "projekty/betard/7/mockup@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }    

                        mockup2: file(relativePath: { eq: "projekty/betard/7/mockup2@2x.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            }  


                            mockup3: file(relativePath: { eq: "projekty/betard/7/mockup3@2x.jpg" }) {
                                childImageSharp {
                                    fluid(maxWidth: 2000, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                                } 


            komp1: file(relativePath: { eq: "projekty/betard/7/komp1@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }  
                
                komp2: file(relativePath: { eq: "projekty/betard/7/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }  
}
            `)

    
    const foto2 = [data.slider_1.childImageSharp.fluid, data.slider_2.childImageSharp.fluid, data.slider_3.childImageSharp.fluid]

   
    
    return(
        <>

        <Rozdzial tytul="FOTOGRAFIA" invert={false} tekst="Fotografia studyjna, reportażowa czy produktowa - jesteśmy tam, gdzie nas potrzebujesz!  W przypadku projektu Macieja Janowskiego realizujemy zdjęcia studyjne, wykorzystywane w materiałach sponsorskich i do tworzenia treści do mediów społecznościowych.  Obsługujemy eventy żużlowe i okołosportowe, podróżując za zawodnikiem w roli oficjalnych fotografów podczas zawodów ligowych czy Indywidualnych Mistrzostw Świata. Realizujemy także fotografie produktowe, niezbędne do obsługi działań ecommerce zawodnika. " nr="07." img={data.okladka.childImageSharp.fluid}>
        
         
   
        <TytulPunkty tekst="Kompleksowa obsługa fotograficzna - realizujemy sesje studyjne, zdjęcia reportażowe oraz fotografię produktową." />

        <Slider_d foto={foto2} />
        

        </Rozdzial>

        
        
        </>
    )
}