import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Cytat from '../comp/komp_cytat'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_info'
import TytulPunkty from '../comp/tytul_punkty'


const informacje = [
    {
        tytul: "DESK RESEARCH",
        opis: "Poznanie branży, analiza raportów branżowych, mediów branżowych, audyt obecności w mediach marki klienta i działań konkurencji."
    },
    {
        tytul: "ANALIZA DANYCH ZASTANYCH",
        opis: "Analiza danych dostarczonych przez klienta - poznanie produktów, wyników sprzedaży, efektywności działań mediowych, analiza modelu biznesowego. Przekrojowe poznanie dynamiki funkcjonowania biznesu."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY WEWNĘTRZNE",
        opis: "Rozmowy bezpośrednie z ekspertami firmy Betard - członkami Zarządu, szefami, działów produktowych, BOK, HR i marketingu. Uzupełnienie wiedzy o modelu firmy, poszerzenie wiedzy o produktach, relacjach z klientami, o ich potrzebach i problemach."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY ZEWNĘTRZNE",
        opis: "Rozmowy z przedstawicielami grup klientów firmy. Poznanie potrzeb, oczekiwanych korzyści, problemów, procesów, i postrzegania marki. Wywiady indywidualne, transkrypcja, kodowanie, analiza."
    },
    {
        tytul: "BADANIA UŻYTECZNOŚCI",
        opis: "Badania użyteczności strony internetowej marki. Analiza user flows, weryfikacja widoczności i dostępności elementów. Opracowanie rekomendacji dla wdrożenia nowej witryny www.teamjanowski.com"
    },
]

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/magic/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                maciek: file(relativePath: { eq: "projekty/magic/1/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="STRATEGIA MARKI" tekst="Aby efektywnie zarządzać wizerunkiem i realizować działania wspierające budowanie rozpoznawalności i postrzegania zawodnika, pierwszym krokiem, który musieliśmy podjąć było opracowanie przejrzystych wytycznych i założeń wizerunkowych. Istotne było, by w sposób komplementarny łączyły one ambicje i oczekiwania Maćka z tym, kim przez lata funkcjonowania na najwyższym poziomie, stał się dla kibiców z Wrocławia, Polski i innych krajów na świecie." nr="01." img={data.okladka.childImageSharp.fluid}>
        <TytulPunkty tekst="Kluczem do efektywnych działań było zrozumienie dynamiki teamu, środowiska żużlowego i zestawienie ich z oczekiwaniami kibiców, którzy identyfikują  się  z Maćkiem. " tekst1="Analiza rynku sportowego i zrozumienie osobowości Maćka, pozwoliły nam na określenie odpowiednich charakterystyk, fundamentalnych dla budowy wizerunku sportowca w mediach."
        tekst2="Uporządkowanie symboliki teamu i zbudowanie dopasowanego systemu identyfikacji do potrzeb wizerunkowych, zoptymalizowały efekty działań mediowych 
        i wpłynęły na sukces komunikacyjny #71." />
        <Slider tytul="Podjęte działania badawcze" informacje={informacje} />
        <Cytat img={data.maciek.childImageSharp.fluid} tytul="„Efektywność, profesjonalizm, kreatywność i dbanie o dobro klienta to główne cechy Odra Studio”" tekst="Maciej Janowski, Team Janowski" /> 
        </Rozdzial>
        
        </>
    )
}