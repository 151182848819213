import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_zdjecie2 from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Intro from '../comp/projekt_intro'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import SliderDuzy from '../comp/slider_duzy'
import Slider from '../comp/slider_foto'
import TytulPunkty from '../comp/tytul_punkty'
export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/magic/3/slider1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/magic/3/slider2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/magic/3/slider3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/magic/3/slider4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/magic/3/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                maciek: file(relativePath: { eq: "projekty/magic/3/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                maciek2: file(relativePath: { eq: "projekty/magic/3/maciek2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus1: file(relativePath: { eq: "projekty/magic/3/bus1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus2: file(relativePath: { eq: "projekty/magic/3/bus2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                

                komp1: file(relativePath: { eq: "projekty/magic/3/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/magic/3/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/magic/3/komp3@2x.jpg"}) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/magic/3/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp5: file(relativePath: { eq: "projekty/magic/3/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                plakat1: file(relativePath: { eq: "projekty/magic/3/plakat1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat2: file(relativePath: { eq: "projekty/magic/3/plakat2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat3: file(relativePath: { eq: "projekty/magic/3/plakat3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    const plakaty = [data.plakat1.childImageSharp.fluid, data.plakat2.childImageSharp.fluid, data.plakat3.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="PRINT" invert={false} tekst="W przypadku projektu Macieja Janowskiego, przestrzenie drukowane rozciągają się od sprzętu sportowego zawodnika (kevlar, kask, ochraniacze, motocykle), przez odzież teamu, merchandising (odzież i akcesoria), busa do wydruków w postaci fankart, kalendarzy, plakatów dla sponsorów i wielu, wielu innych. 
        W trakcie ponad 5-letniej współpracy zaprojektowaliśmy i wydrukowaliśmy tysiące materiałów - część z nich prezentujemy poniżej, zobacz!" nr="03." img={data.okladka.childImageSharp.fluid}>

        
         
        <TytulPunkty tekst="Niezależnie od nośnika, wszystkie materiały teamu dopracowane są  w najdrobniejszych szczegółach, z uwzględnieniem wytycznych sponsorów, lig a także dobrych praktyk brandingu i projektowania graficznego." />


        <Komp_zdjecie2 invert={true} img={data.komp1.childImageSharp.fluid} tytul="TEAM JANOWSKI" tekst="Biel, czerwień i czerń to paleta barw wywodząca się z flagi Polski, połączona z czernią - ulubionym kolorem Maćka, nawiązującym do „czarnego” sportu, jakim określa się speedway." />

        <TytulPunkty tekst="W ciągu pięciu lat stworzyliśmy pięć kolekcji kapsułowych dla kibiców, realizując tysiące zamówień online i w punktach sprzedaży stacjonarnej, które uruchomiliśmy samodzielnie, w ramach projektu." />

        <Img fluid={data.maciek.childImageSharp.fluid} />
        <Komp_kol  img1={data.komp2.childImageSharp.fluid} img2={data.komp3.childImageSharp.fluid}  />
        <Komp_kol style={{paddingTop: '0px'}}  img1={data.komp4.childImageSharp.fluid} img2={data.komp5.childImageSharp.fluid}  />

        <Slider tytul="Produkty w merchu" foto={foto} />
        <Img fluid={data.maciek2.childImageSharp.fluid} />

        <Intro tytul="Jak strategicznie myśleć " tekst="Projekt busa Team Janowski, którym Maciek, wraz z zespołem mechaników i sprzętem, przemieszcza się po Europie." />

        <Img fluid={data.bus1.childImageSharp.fluid} />
        <Img fluid={data.bus2.childImageSharp.fluid} />

        <Punkt tytul="DZIĘKI ZA WSPARCIE" tekst="Ze względu na specyfikę sportu żużlowego, Team pokonuje w busie około 200.000 kilometrów rocznie a pojazd jest jednym z głównych narzędzi pracy. Istotne jest, by poza rolą transportową, był także atrakcyjnym nośnikiem reklamowym dla sponsorów i powodem do dumy dla Maćka, dla którego bywa drugim domem." />

        <SliderDuzy foto={plakaty} />
        
        

        
        
        </Rozdzial>
        
        </>
    )
}