import React, {useState} from 'react'
import style from './wyniki.module.css'
import Img from "gatsby-image"
import strzalka from './strzalka.svg'

export default function Wyniki(props, wynik1, wynik2, wynik3, tekst1, tekst2, tekst3, postac) {



  

    return(
        <>
        <div className={style.wrapper}>

            <div className={style.box}>
                <Img className={style.postac} fluid={props.postac} />
                <p>Wyniki</p>
                <div className={style.innerbox}>

                    <div className={style.wynik} >
                        <img src={strzalka} />
                        <h3>{props.wynik1}</h3>
                        <span className="przycisk">
                            {props.tekst1}
                        </span>
                    </div>

                    <div className={style.wynik} >
                        <img src={strzalka} />
                        <h3>{props.wynik2}</h3>
                        <span className="przycisk">
                            {props.tekst2}
                        </span>
                    </div>

                    <div className={style.wynik} >
                        <img src={strzalka} />
                        <h3>{props.wynik3}</h3>
                        <span className="przycisk">
                            {props.tekst3}
                        </span>
                    </div>
                </div>
            </div>
           
        
        </div>
        </>
    )
}