import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_zdjecie from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
import SliderDuzy from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'



export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/magic/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                },
                slider1: file(relativePath: { eq: "projekty/magic/2/slider1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider2: file(relativePath: { eq: "projekty/magic/2/slider2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider3: file(relativePath: { eq: "projekty/magic/2/slider3@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp1: file(relativePath: { eq: "projekty/magic/2/komp1@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp2: file(relativePath: { eq: "projekty/magic/2/komp2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek: file(relativePath: { eq: "projekty/magic/2/maciek@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek2: file(relativePath: { eq: "projekty/magic/2/maciek2@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                brandbook: file(relativePath: { eq: "projekty/magic/2/brandbook@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
               
                    

               

            }
            `)

    


    const foto = [data.slider1.childImageSharp.fluid, data.slider2.childImageSharp.fluid, data.slider3.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJAWIZUALNA MARKI" invert={true} tekst="Celem naszych działań było uporządkowanie symboliki i zbudowanie systemu wizerunkowego, który uwzględniając bardzo zróżnicowane pola ekspozycji, pozwoli na budowanie spójnych i wyjątkowych materiałów komunikacyjnych. Skomplikowanie zadania wynikało z dużej ilości nośników, za pomocą których komunikuje się Team (media własne, sprzęt sportowy, merchandising, etc.) i ograniczonego wpływu na zewnętrzne ekosystemy, które w przypadku sportowców, są kluczowe dla ich efektywnej obecności w przestrzeni publicznej (earned media, ekosystemy sponsorów, kluby sportowe, organizacje i związki sportowe, itp.). " nr="02." img={data.okladka.childImageSharp.fluid}>


      
        <TytulPunkty tekst1="Mocna osobowość Maćka i personalny charakter projektu wymagały od nas pogodzenia oczekiwań stylistycznych naszego klienta, przy jednoczesnym wpisaniu go w ramy atrakcyjne dla zróżnicowanych grup kibiców i możliwe do produkcji w przyjętej skali." tekst2="Mnogość symboli i brak jasnego systemu wizualnego obniżały efektywność  komunikacji teamu. Naszym zadaniem było wprowadzenie łagodnych korekt wizerunkowych, wykorzystujących część z dotychczasowych elementów, w tym znak zawodnika, który stworzony został przez firmę Red Bull." tekst="Naszym zadaniem było stworzenie nowej jakości w świecie speedwaya, wpisanie wizerunku Maćka w nowoczesne media i efektywne zagospodarowanie tradycyjnych nośników, takich jak sprzęt sportowy, bus teamu, merchandising czy materiały sponsorskie." />  


        <SliderDuzy foto={foto} />

        <Intro tytul="System" tekst="Stworzenie systemu pozwoliło nam zoptymalizować efekt i uzyskać  powtarzalność, co jest bardzo ważne  w sytuacji, kiedy produkcja materiałów komunikacyjnych jest relatywnie duża w skali funkcjonowania pojedynczego teamu sportowego. Ze względu na znaczną rozpoznawalność  Maćka w momencie rozpoczęcia projektu, zdecydowaliśmy się  na ewolucję i zagospodarowanie dotychczasowych symboli w nowej odsłonie. " />
    

        

       


        <Komp_kol tekst1="Dobrana kolorystyka nawiązuje do flagi Polski i uzupełniona została o czerń - ulubiony kolor Maćka, przywodzący na myśl żużel, popularnie określany mianem czarnego sportu. Taka paleta pozwala nam na zachowanie spójności w materiałach komunikacyjnych, przy jednoczesnym wyróżnieniu zawodnika spośród innych żużlowców i sportowców indywidualnych w Polsce. " tytul1="Kolorystyka" tekst2="Zastosowane kroje mają swoje korzenie w czasach, w których nie pracowaliśmy jeszcze z Magic’em. Krój Night Train pojawił się w logotypie stworzonym przez markę Red Bull i naszym zamysłem było szersze zastosowanie go w identyfikacji, dla uspójnienia wizerunku zawodnika. Krój Chivo dobrany został jako kontrastowy krój tworzący przeciwwagę dla mocno zdobionego „Night Train”. Stosowany jest w dłuższych tekstach." tytul2="Typografia" img1={data.komp1.childImageSharp.fluid}  img2={data.komp2.childImageSharp.fluid} />

        <Img fluid={data.maciek.childImageSharp.fluid} />
        <Img fluid={data.maciek2.childImageSharp.fluid} />

        <Komp_zdjecie img={data.brandbook.childImageSharp.fluid} tytul="Brandbook" tekst="Brandbook określa wszystkie założenia dotyczące wizerunku marki osobistej Macieja Janowskiego, połączone z założeniami dotyczącymi wszystkich elementów architektury marki. Inne marki zależne to „Team Janowski” - marka zbierająca wszystkie działania „Team’u” oraz Magic - prywatna marka Maćka."
        />
        
        
        </Rozdzial>
        
        </>
    )
}