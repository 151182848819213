import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_foto'
import TytulPunkty from '../comp/tytul_punkty'





export default function Print() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/magic/5/slider/1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/magic/5/slider/2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }


                slider_3: file(relativePath: { eq: "projekty/magic/5/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/magic/5/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_5: file(relativePath: { eq: "projekty/betard/5/slider2/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_6: file(relativePath: { eq: "projekty/betard/5/slider2/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_7: file(relativePath: { eq: "projekty/betard/5/slider2/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_8: file(relativePath: { eq: "projekty/betard/5/slider3/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_9: file(relativePath: { eq: "projekty/betard/5/slider3/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_10: file(relativePath: { eq: "projekty/betard/5/slider3/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_d1: file(relativePath: { eq: "projekty/betard/5/slider_d1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }
    
                slider_d2: file(relativePath: { eq: "projekty/betard/5/slider_d2@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/magic/5/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta1: file(relativePath: { eq: "projekty/betard/5/oferta@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                oferta2: file(relativePath: { eq: "projekty/betard/5/oferta2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta3: file(relativePath: { eq: "projekty/betard/5/oferta3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta4: file(relativePath: { eq: "projekty/betard/5/oferta4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta5: file(relativePath: { eq: "projekty/betard/5/oferta5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta6: file(relativePath: { eq: "projekty/betard/5/oferta6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta7: file(relativePath: { eq: "projekty/betard/5/oferta7@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta8: file(relativePath: { eq: "projekty/betard/5/oferta8@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/5/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/5/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/betard/5/komp3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/betard/5/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp5: file(relativePath: { eq: "projekty/betard/5/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp6: file(relativePath: { eq: "projekty/betard/5/komp6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp7: file(relativePath: { eq: "projekty/betard/5/komp7@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp8: file(relativePath: { eq: "projekty/betard/5/komp8@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp9: file(relativePath: { eq: "projekty/betard/5/komp9@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp10: file(relativePath: { eq: "projekty/betard/5/komp10@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp11: file(relativePath: { eq: "projekty/betard/5/komp11@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp12: file(relativePath: { eq: "projekty/betard/5/komp12@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp13: file(relativePath: { eq: "projekty/betard/5/komp13@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp14: file(relativePath: { eq: "projekty/betard/5/komp14@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full1: file(relativePath: { eq: "projekty/magic/5/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full2: file(relativePath: { eq: "projekty/magic/5/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                kol_tekst1: file(relativePath: { eq: "projekty/betard/5/kol_tekst1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }



            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]

    const foto2 = [data.slider_5.childImageSharp.fluid, data.slider_6.childImageSharp.fluid, data.slider_7.childImageSharp.fluid]

    const slider_d = [data.slider_d1.childImageSharp.fluid, data.slider_d2.childImageSharp.fluid]

    const slider_d2 = [data.slider_8.childImageSharp.fluid, data.slider_9.childImageSharp.fluid, data.slider_10.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="DIGITAL
        DESIGN" invert={false} tekst="Współpraca z Maciejem Janowskim, szczególnie w obszarze obsługi mediowej zawodnika, sprawia, że oprócz dużej ilości materiałów fizycznych, tworzymy także rozwiązania cyfrowe. Przeznaczone są one głównie do wykorzystania w mediach społecznościowych zawodnika lub przez sponsorów Teamu w ich własnych ekosystemach online, ze szczególnym uwzględnieniem marek Red Bull oraz Betard. Zobacz przykłady tworzonych rozwiązań. Aby dowiedzieć się więcej obserwuj zawodnika w mediach społecznościowych (Facebook, Instagram, TikTok)." nr="05." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="Tworzymy kompleksowe rozwiązania cyfrowe - od strony internetowej, przez treści do social media - Filmy, animacje i statyczne materiały graficzne." tekst1="Stała forma współpracy sprawia, że po naszej stronie jest dostarczenie odpowiednich kompetencji, niezależnie od wyzwania, którego wymaga konkretna sytuacja mediowa. " tekst2="Wszystkie tworzone rozwiązania cyfrowe wynikają bezpośrednio z przyjętego systemu tożsamości i obecności wizualnej marki. Dzięki temu, mimo upływu czasu, komunikacja pozostaje spójna 
    i charakterystyczna dla zawodnika." />

    <Img fluid={data.full1.childImageSharp.fluid} />

    <Slider tytul="Rodzaje ilustracji" foto={foto} />

    <TytulPunkty tekst="Grafiki meczowe tworzone są w ściśle ustalonym systemie, zarówno w kontekście pojedynczego meczu jak i zróżnicowania pod względem rodzaju zawodów (liga polska, szwedzka, Speedway Grand Prix, kadra Polski). Choć linia graficzna wynika bezpośrednio z założeń wizualnych marki, każdy kolejny sezon posiada indywidualny charakter, by utrzymać i rozwijać zainteresowanie i zaangażowanie kibiców." />

    <Img fluid={data.full2.childImageSharp.fluid} />
        
       
        </Rozdzial>
        
        </>
    )
}