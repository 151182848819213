import React from 'react'
import style from './komp_zdjecie.module.css'
import Img from "gatsby-image"

export default function komp_zdjecie(props) {

    if(props.invert) {
        return(
        <>
            <div className={style.wrapper_inverted}>
                <Img fluid={props.img} />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}} className={style.tekst}>
                         <h4 style={{fontFamily: 'sectra'}}>{props.tytul}</h4>
                        <span className="przycisk">{props.tekst}</span>
                </div>
            </div>
        </>
        )
    }
    else {
        return(
            <>
                <div className={style.wrapper}>
                    <Img fluid={props.img} />
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}} className={style.tekst}>
                        <h4 style={{fontFamily: 'sectra'}}>{props.tytul}</h4>
                        <span className="przycisk">{props.tekst}</span>
                    </div>
                </div>
            </>
        )
    }
   
}